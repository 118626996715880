"use client";

import { useCallback, useState } from "react";
import { Card, Tabs, Text } from "@shopify/polaris";
import { STATIC_URL } from "@/constants-game.ts";
import Image from "next/image";
import { refs } from "@/utils/refs";
import { EVENTS } from "@/utils/events";

const tabs = [
  {
    id: "player-stats",
    content: "Player stats",
    accessibilityLabel: "Player stats",
    panelID: "player-stats-panel",
    image: `${STATIC_URL}/player-stats.webp`,
  },
  // {
  //   id: "event-decks",
  //   content: "Best event decks",
  //   panelID: "event-decks-panel",
  // },
  // {
  //   id: "leaderboards",
  //   content: "Leaderboards",
  //   panelID: "leaderboards-panel",
  // },
  {
    id: "cards",
    content: "Cards",
    accessibilityLabel: "Cards",
    panelID: "cards-panel",
    image: `${STATIC_URL}/homepage/cards.webp`,
  },
  {
    id: "top-clanwars",
    content: "Top Clan Wars",
    accessibilityLabel: "Top clan wars",
    panelID: "top-clanwars-panel",
    image: `${STATIC_URL}/homepage/topclanwars.webp`,
  },
  {
    id: "top-clans",
    content: "Top Clans",
    accessibilityLabel: "Top clan",
    panelID: "top-clans-panel",
    image: `${STATIC_URL}/homepage/topclans.webp`,
  },
  // {
  //   id: "pro-decks",
  //   content: "Pro Clash Royale decks",
  //   panelID: "pro-decks-panel",
  // },
  // {
  //   id: "card-synergies",
  //   content: "Card synergies",
  //   panelID: "card-synergies-panel",
  // },
];

export default function Features() {
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setSelected(selectedTabIndex);
    refs.pom(EVENTS.ENGAGEMENT, {
      type: "TAB_CLICK",
      value: tabs[selectedTabIndex].content,
    });
  }, []);

  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="flex flex-col gap-1 items-center">
        <Text as="h2" variant="headingMd" tone="subdued">
          Royale Secrets
        </Text>
        <Text as="h3" variant="headingLg">
          Unlock Your Path to Victory in Clash Royale
        </Text>
      </div>
      <div className="w-full">
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          <Card>
            <Image
              src={tabs[selected].image ?? ""}
              alt={tabs[selected].content}
              loading="lazy"
              width={1918}
              height={1112}
            />
          </Card>
        </Tabs>
      </div>
    </div>
  );
}
