"use client";

import { createElement, useEffect, memo, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import type { LeaderboardsClans } from "@/models/leaderboards";
import {
  Button,
  Card,
  SkeletonBodyText,
  Text,
  Scrollable,
} from "@shopify/polaris";
import { MAX_CLAN_MEMBERS } from "@/constants-game.ts";
import ListItem from "@/app/homepage/ListItem.tsx";
import useAllianceImage from "@/utils/use-alliance-image";
import Image from "next/image";
import { ShieldPersonIcon } from "@shopify/polaris-icons";
import { SCROLLABLE_HEIGHT } from "@/app/homepage/constants.ts";
import useTopClans from "@/app/topclans/use-top-clans.ts";
import { refs } from "@/utils/refs.ts";
import { EVENTS } from "@/utils/events.ts";

const PLACEHOLDER_CLAN = {
  name: "",
  tag: "",
  badgeId: 0,
  previousRank: 0,
  members: 0,
  rank: 0,
  location: {
    name: "",
    id: 0,
    isCountry: true,
    countryCode: "",
  },
  clanScore: 0,
};

const ClanMemo = memo(function ClanComponent(props: Clan) {
  return (
    <ListItem isLight={Boolean(props.index % 2)}>
      {createElement(_Clan, props)}
    </ListItem>
  );
});

export default function TopClans() {
  const { t } = useTranslation();
  const [isClient, setIsClient] = useState(false);
  const { topClans, error, isLoading } = useTopClans();
  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <Card padding="0">
      <div className="flex justify-between items-center gap-4 px-4 py-3">
        <div className="flex gap-1 items-center">
          <ShieldPersonIcon className="w-5" />
          <Text as="h2" variant="headingMd">
            {t("topClans", "Top Clans")}
          </Text>
        </div>
        <Button
          url="/topclans"
          onClick={() => {
            refs.pom(EVENTS.ENGAGEMENT, {
              type: "CLICK",
              destination: "/topclans",
            });
          }}
        >
          View All
        </Button>
      </div>
      <Scrollable shadow style={{ height: SCROLLABLE_HEIGHT + "px" }} focusable>
        {!isClient || isLoading ? (
          new Array(5)
            .fill(undefined)
            .map((_, idx) => (
              <ClanMemo
                key={idx}
                index={idx}
                clan={PLACEHOLDER_CLAN}
                isLoading
              />
            ))
        ) : error ? (
          <div className="p-4 pt-0">
            {t(
              "topClansError",
              "Sorry, we are unable to get top clans for this region"
            )}
          </div>
        ) : (
          topClans?.topClans
            .slice()
            .sort((a, b) => a.rank - b.rank)
            .slice(0, 10)
            .map((clan, idx) => (
              <ClanMemo key={clan.tag} clan={clan} index={idx} />
            ))
        )}
      </Scrollable>
    </Card>
  );
}

function _Clan({ clan, isLoading = false, index }: Clan) {
  const { t } = useTranslation();
  const { allianceImgUrl } = useAllianceImage(clan.badgeId);
  return (
    <div className="flex gap-2 items-center">
      {isLoading ? index + 1 : clan.rank}
      <Image
        src={allianceImgUrl}
        alt={`Clan badge ${clan.badgeId}`}
        width={37}
        height={43}
      />
      <div className="w-full">
        {isLoading ? (
          <SkeletonBodyText />
        ) : (
          <Fragment>
            <div className="text-sm font-semibold">{clan.name}</div>
            <div className="flex xl:flex-row lg:flex-col flex-row xl:gap-2 md:gap-0 gap-2 justify-between w-full text-xs font-light">
              <div>{clan.tag}</div>
              <div>
                {t("clanMembersCount", "Members: {{value}}/{{max}}", {
                  value: clan.members,
                  max: MAX_CLAN_MEMBERS,
                })}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

type Clan = {
  clan: LeaderboardsClans["topClans"][0];
  index: number;
  isLoading?: boolean;
};
