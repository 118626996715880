"use client";

import { Card, Box, Text, Badge, Button, Scrollable } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef } from "react";
import Image from "next/image";
import { SCROLLABLE_HEIGHT } from "@/app/homepage/constants.ts";
import { useAppSelector } from "@/utils/hooks.ts";
import CardImage from "@/components/CardImage.tsx";
import { refs } from "@/utils/refs.ts";
import { EVENTS } from "@/utils/events.ts";

export default function CardsPreview() {
  const { t } = useTranslation();
  const cards = useAppSelector((state) => state.static.cards);
  const cardsArr = useMemo(() => Object.values(cards ?? {}), [cards]);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current && cardsArr.length) {
      setTimeout(() => {
        const parentElement = ref.current?.parentElement;
        if (!parentElement) return;
        autoScroll(parentElement, 0.5);
      }, 1e3);
    }
  }, [ref.current, cardsArr]);
  return (
    <Card padding="400">
      <div className="flex justify-between items-center gap-4 pb-3">
        <div className="flex gap-1 items-center">
          <Text as="h2" variant="headingMd">
            {t("cards", "Cards")}
          </Text>
        </div>
        <Button
          url="/cards"
          onClick={() => {
            refs.pom(EVENTS.ENGAGEMENT, {
              type: "CLICK",
              destination: "/cards",
            });
          }}
        >
          View Cards
        </Button>
      </div>
      <Scrollable
        shadow
        horizontal={false}
        style={{ height: SCROLLABLE_HEIGHT + "px" }}
      >
        <div
          className="grid lg:grid-cols-4 sm:grid-cols-8 grid-cols-5"
          ref={ref}
        >
          {cardsArr.map((i) => (
            <CardImage card={i} key={i.id} isLeader />
          ))}
        </div>
      </Scrollable>
    </Card>
  );
}

function autoScroll(element: HTMLElement, speed: number) {
  let scrollPosition = element.scrollTop;
  const scrollHeight = element.scrollHeight;
  function scrollStep() {
    if (scrollPosition < scrollHeight - element.clientHeight) {
      scrollPosition += speed;
      element.scrollTop = scrollPosition;
      requestAnimationFrame(scrollStep);
    }
  }
  requestAnimationFrame(scrollStep);
}
