"use client";

import { useTranslation } from "react-i18next";
import { Badge, Tooltip, Text } from "@shopify/polaris";

export default function HeroTitle() {
  const { t } = useTranslation();
  const tooltipText = t(
    "betaMessage",
    "We're in beta: Enjoy early access and share your feedback as we refine our site!"
  );
  return (
    <div className="z-10 flex col items-center gap-2">
      <div
        className="text-white"
        style={{ textShadow: "1px 1px 6px var(--p-color-icon-brand)" }}
      >
        <Text variant="heading2xl" as="h1" tone="inherit">
          Royale Tactics
        </Text>
      </div>
      <Tooltip
        preferredPosition="above"
        content={tooltipText}
        accessibilityLabel={tooltipText}
        active
      >
        <Badge tone="info" size="large">
          {t("beta", "Beta")}
        </Badge>
      </Tooltip>
    </div>
  );
}
