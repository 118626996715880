"use client";

import { useTranslation } from "react-i18next";
import type { Events } from "@/models/events.ts";
import { EventsModel } from "@/models/events.ts";
import {
  Card,
  Button,
  Text,
  SkeletonBodyText,
  Box,
  Scrollable,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon } from "@shopify/polaris-icons";
import { useMemo, useState, useCallback, useEffect } from "react";
import Challenge from "@/app/homepage/Challenge.tsx";
import useSWR from "swr";
import { useAppSelector } from "@/utils/hooks.ts";
import { API } from "@/constants-app";
import { captureException } from "@sentry/react";
import fetcher from "@/utils/fetcher.ts";
import { SCROLLABLE_HEIGHT } from "@/app/homepage/constants.ts";
import { refs } from "@/utils/refs.ts";
import { EVENTS } from "@/utils/events.ts";

const DIRECTIONS = { ASC: "ASC", DESC: "DESC" };

export default function Events() {
  const { t } = useTranslation();
  const latest = useAppSelector((state) => state.static.latest);
  const [isClient, setIsClient] = useState(false);
  const { data, error, isLoading } = useSWR<Events>(
    latest?.events ? API + "/events/" + latest.events : null,
    fetcher(EventsModel)
  );
  const { events, isError } = useMemo(() => {
    try {
      if (isLoading || !data) return { events: [] };
      return { events: EventsModel.parse(data)?.events };
    } catch (e) {
      console.error(e);
      captureException(e);
      return {
        events: [],
        isError: true,
      };
    }
  }, [data, isLoading]);
  const [sortDirection, setSortDirection] = useState(DIRECTIONS.DESC);
  const handleToggle = useCallback(() => {
    setSortDirection((prev) =>
      prev === DIRECTIONS.DESC ? DIRECTIONS.ASC : DIRECTIONS.DESC
    );
    refs.pom(EVENTS.ENGAGEMENT, { type: "EVENT_SORTING" });
  }, []);
  const isDescending = useMemo(
    () => sortDirection === DIRECTIONS.DESC,
    [sortDirection]
  );
  const [challengesRunning, challengesCompleted] = useMemo(() => {
    const accumulator: [Array<EventItem>, Array<EventItem>] = [[], []];
    const results =
      events.reduce((acc, event) => {
        for (const challenge of event.challenges) {
          acc[Number(Date.now() > event.endTime)].push({
            ...challenge,
            startTime: event.startTime,
            endTime: event.endTime,
          });
        }
        return acc;
      }, accumulator) ?? accumulator;
    for (const i of results)
      i.sort((a, b) =>
        isDescending ? b.endTime - a.endTime : a.endTime - b.endTime
      );
    return results;
  }, [events, isDescending]);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <Card padding="0">
      <div className="flex justify-between items-center gap-4 px-4 py-3">
        <Text as="h2" variant="headingMd">
          {isDescending
            ? t("latestEvents", "Latest Events and Challenges")
            : t("oldestEvents", "Oldest Events and Challenges")}
        </Text>
        <Button
          onClick={handleToggle}
          icon={isDescending ? ChevronDownIcon : ChevronUpIcon}
          accessibilityLabel="Sort by date"
          disabled={
            isLoading ||
            error ||
            isError ||
            !Array.isArray(data?.events) ||
            !data.events.length
          }
        />
      </div>
      <Scrollable shadow style={{ height: SCROLLABLE_HEIGHT + "px" }} focusable>
        <div>
          {!isClient || isLoading ? (
            <EventsLoading />
          ) : error || isError ? (
            <EventsError />
          ) : (
            challengesRunning.map((challenge, idx) => {
              const key = createIndexKey(challenge);
              return <Challenge key={key} challenge={challenge} index={idx} />;
            })
          )}
        </div>
        <div className="px-4 py-3">
          <Text as="h2" variant="headingMd">
            {t("finishedEvents", "Finished Events and Challenges")}
          </Text>
        </div>
        <div>
          {!isClient || isLoading ? (
            <EventsLoading />
          ) : error || isError ? (
            <EventsError />
          ) : (
            challengesCompleted.map((challenge, idx) => {
              const key = createIndexKey(challenge);
              return <Challenge key={key} challenge={challenge} index={idx} />;
            })
          )}
        </div>
      </Scrollable>
    </Card>
  );
}

function createIndexKey(challenge: EventItem): string {
  return [
    challenge.startTime,
    challenge.endTime,
    challenge.name,
    challenge.id,
  ].join("");
}

type EventItem = Events["events"][0]["challenges"][0] & {
  startTime: number;
  endTime: number;
};

export function EventsLoading() {
  return (
    <div className="px-4">
      <SkeletonBodyText lines={5} />
    </div>
  );
}

export function EventsError() {
  const { t } = useTranslation();
  return (
    <Box background="bg-surface-secondary" padding="400">
      <Text as="p" tone="critical">
        {t("eventDataFailed", "Failed to get event data")}
      </Text>
    </Box>
  );
}
