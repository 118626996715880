"use client";

import { useTranslation } from "react-i18next";
import { Text } from "@shopify/polaris";

export default function ShowcaseTitle() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-1 items-center">
      <Text as="h2" variant="headingMd" tone="subdued">
        {t("headingDominateClash", "Dominate Clash Royale")}
      </Text>
      <Text as="h3" variant="headingLg">
        {t(
          "subheadingDominateClash",
          "Essential Features for Every Royale Player"
        )}
      </Text>
    </div>
  );
}
