"use client";

import { Box } from "@shopify/polaris";

const padding = "400";

export default function ListItem({
  isLight,
  children,
}: Readonly<{
  isLight: boolean;
  children: React.ReactNode;
}>) {
  return (
    <Box
      padding={padding}
      background={isLight ? undefined : "bg-surface-secondary"}
    >
      {children}
    </Box>
  );
}
