"use client";

import { Page as PolarisPage } from "@shopify/polaris";

export default function Page({ children }: { children: React.ReactNode }) {
  return (
    <div className="px-2 w-full">
      <PolarisPage>{children}</PolarisPage>
    </div>
  );
}
