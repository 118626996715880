import { Alliances } from "@/models/alliances.ts";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "@/utils/hooks.ts";
import { CLAN_BADGE_DEFAULT, STATIC_URL } from "@/constants-game.ts";

export default function useAllianceImage(badgeId?: number | string): {
  allianceImgUrl: string;
  getAllianceImgUrl: (badgeId?: number | string) => string;
} {
  const alliances = useAppSelector((state) => state.static.alliances);
  const getAllianceImgUrl = useCallback(
    (badgeId?: number | string) => {
      const id = (badgeId?.toString() ?? "") as unknown as keyof Alliances;
      const alliance = alliances[id];
      return `${STATIC_URL}/alliance/${alliance ?? CLAN_BADGE_DEFAULT}.webp`;
    },
    [alliances]
  );
  const allianceImgUrl = useMemo(
    () => getAllianceImgUrl(badgeId),
    [alliances, badgeId]
  );
  return {
    allianceImgUrl,
    getAllianceImgUrl,
  };
}
