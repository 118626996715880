"use client";

import { useTranslation } from "react-i18next";
import { memo } from "react";
import ListItem from "@/app/homepage/ListItem.tsx";
import { formatDistanceToNow } from "date-fns";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@shopify/polaris";
import type { Events } from "@/models/events.ts";

const EVENT_THRESHOLD = 1000 * 60 * 60 * 24 * 30; // 30 days

export default memo(function ChallengeComponent({
  challenge,
  index,
}: {
  challenge: EventItem;
  index: number;
}) {
  const { t } = useTranslation();
  const isPastChallenge = Date.now() > challenge.endTime;
  return (
    <ListItem isLight={Boolean(index % 2)}>
      <Tooltip content={challenge.description} persistOnClick>
        <div className="flex gap-2 justify-between items-center">
          <div>
            <div className="text-sm font-medium">{challenge.name}</div>
            {challenge.endTime < Date.now() + EVENT_THRESHOLD ? (
              <div className="text-xs">
                {isPastChallenge
                  ? t("challengeEnded", "Challenge ended {{time}} ago", {
                      time: formatDistanceToNow(challenge.endTime, {
                        includeSeconds: true,
                        addSuffix: false,
                      }),
                    })
                  : t("challengeToComplete", "{{time}} to complete", {
                      time: formatDistanceToNow(challenge.endTime, {
                        includeSeconds: true,
                        addSuffix: false,
                      }),
                    })}
              </div>
            ) : null}
          </div>
          <InformationCircleIcon className="h-5 w-5" />
        </div>
      </Tooltip>
    </ListItem>
  );
});

type EventItem = Events["events"][0]["challenges"][0] & {
  startTime: number;
  endTime: number;
};
